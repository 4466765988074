/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/SpaceGrotesk-Medium.ttf');
}

@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/SpaceGrotesk-Regular.ttf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.ios {
  .hidden-ios {
    display: none;
  }

  ion-card {
    box-shadow: none;
  }
}

.md {
  .hidden-md {
    display: none;
  }
}

.text-dark {
  color: var(--ion-color-dark);
}

